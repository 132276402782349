import React, { useEffect, useState } from "react";
import Selection from "../components/Selection";
import { useLogin } from "../hooks/useLogin";
import { getCities } from "../api/general/citiesApi";
import { Navigate, useNavigate } from "react-router-dom";
import { handleApiError } from "../api/api";
import { getFacilitiesByCityId } from "../api/general/facilitiesApi";

interface City {
  cityID: number;
  cityName: string;
}

const FindCompanion = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initial page load state
  const [loadingFacilities, setLoadingFacilities] = useState(false); // Separate state for facilities loading
  const {
    cities,
    setCities,
    facilities,
    setFacilities,
    selectedFacilityId,
    setSelectedFacilityId,
    selectedCityId,
    setSelectedCityId,
  } = useLogin();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const citiesData = await getCities();
        setCities(citiesData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching cities:", error);
      } finally {
        setLoading(false); // Stop initial loading
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchFacilities = async () => {
      if (selectedCityId) {
        setLoadingFacilities(true); // Start loading for facilities
        try {
          const facilitiesData = await getFacilitiesByCityId(selectedCityId);
          setFacilities(facilitiesData);
        } catch (error) {
          handleApiError(error);
          console.error("Error fetching facilities:", error);
        } finally {
          setLoadingFacilities(false); // Stop facilities loading
        }
      }
    };

    fetchFacilities();
  }, [selectedCityId]);

  const handleCityChange = (value: string) => {
    const cityId = parseInt(value);
    setSelectedCityId(cityId);
  };

  const handleFacilityChange = (value: string) => {
    const facilityId = parseInt(value);
    console.log(facilityId);
    setSelectedFacilityId(facilityId);
  };

  const handleSearch = () => {
    navigate(`/find-companions?ct=${selectedCityId}&rs=1`);
  };

  return (
    <div className="relative">
      {(loading) && (
        <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center z-50">
          <div className="loader border-t-4 border-b-4 border-[#038aae] rounded-full w-12 h-12 animate-spin"></div>
        </div>
      )}
      {!loading && ( // Only show the content once loading is false
        <div className="mt-[88px] w-[400] sm:w-[500px] mx-auto py-6 px-3 sm:px-0">
          <div className="py-4 text-center">
            <h2 className="py-2 text-3xl font-bold">Compassionate Companions for your Loved Ones</h2>
            <p className="py-3 max-w-[500px] mx-auto">
              Ensure your loved ones feel connected, valued, and supported with our personalized companion services. Schedule a booking to give them the gift of meaningful companionship.
            </p>
          </div>
          <h2 className="text-green text-2xl py-3 text-center">Get Started Now</h2>
          <div className="py-3">
            <Selection
              label="City"
              options={cities.map((city) => ({
                value: city.cityID.toString(),
                label: city.cityName,
              }))}
              onChange={handleCityChange}
            />
          </div>
          <button
            onClick={handleSearch}
            disabled={!selectedCityId}
            className={`py-2 px-4 text-white ${
              selectedCityId ? "bg-[#038aae]" : "bg-gray-400 cursor-not-allowed"
            } rounded-lg`}
          >
            Search
          </button>
        </div>
      )}
    </div>
  );
};

export default FindCompanion;
