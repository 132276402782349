import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface CustomDatePickerProps {
  selectedDate: Date;
  label: string;
  onSelectDate: (date: Date) => void;
  minDate?: Date; // Optional minDate prop
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDate,
  onSelectDate,
  label,
  minDate // Destructure the minDate prop
}) => {
  const [startDate, setStartDate] = useState<Date>(selectedDate);

  const handleChange = (date: Date) => {
    setStartDate(date);
    onSelectDate(date);
  };

  return (
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="date" className="text-sms text-gray-500">
        {label}
      </label>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        dateFormat="MM/dd/yyyy"
        className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:border-[#038aae]"
        minDate={minDate} // Apply the minDate prop to DatePicker
      />
    </div>
  );
};

export default CustomDatePicker;