import React from "react";
import { Link } from "react-router-dom";
const Explore1 = require("../../images/explore1.jpg");
const Explore2 = require("../../images/Explore.jpg");

const Explore = () => {
  return (
    <div className="">
      <div className="flex item-start justify-center flex-col mt-8 mb-14">
        <div className="font-bold text-3xl md:text-4xl py-3 mx-auto">Explore</div>
        <div className="bg-[#038aae] h-1 w-24 mx-auto relative left-[-5px]"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-7xl mx-auto py-16 px-4">
        <div className="relative flex items-center justify-center">
          <div
            className="rounded-lg h-[300px] md:h-[500px] w-[200px] md:w-[400px] relative overflow-hidden flex items-center justify-center z-[9]"
            style={{
              backgroundImage: `url(${Explore1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* <img src={Explore1} alt="explore" className="h-[400px] w-[500px]" /> */}
          </div>
          <div className="hidden md:flex md:h-[400px] w-32 md:w-64 border border-[5px] border-[#038aae] md:absolute top-[-42px] md:top-[-40px] right-[8em] md:right-[4em]"></div>
        </div>

        <div className="flex items-center md:items-start justify-center flex-col w-full">
          <h2 className="font-bold text-2xl md:text-3xl py-2">View companions</h2>
          <p className="text-sm md:text-[15px] text-center md:text-start w-[25em] my-3 md:my-8">
            Experience the difference. Find trustworthy companions committed to
            caring and engaging with older adults.
          </p>
          <Link
            to="find-companion"
            className="text-center my-3 text-sm bg-[#038aae] text-white px-2 py-3 w-32 rounded-lg"
          >
            Start Now
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-7xl mx-auto py-16">
        <div className="hidden md:flex items-center md:items-start justify-center flex-col w-full md:pl-[15em]">
          <h2 className="font-bold text-3xl py-2">Become a companion</h2>
          <p className="text-start w-[25em] my-8">
            Make a difference. Help in caring and engaging with older adults
            while earning an income.
          </p>
          <Link
            to="become-companion"
            className="text-center my-3 text-sm bg-[#038aae] text-white px-2 py-3 w-32 rounded-lg"
          >
            Start Now
          </Link>
        </div>
        <div className="relative flex items-center justify-center my-6">
          <div
            className="rounded-lg h-[300px] md:h-[500px] w-[200px] md:w-[400px] relative overflow-hidden flex items-center justify-center z-[9]"
            style={{
              backgroundImage: `url(${Explore2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* <img src={Explore1} alt="explore" className="h-[300px] w-[500px]" /> */}
          </div>
          <div className="hidden md:flex md:h-[400px] w-32 md:w-64 border border-[5px] border-[#038aae] md:absolute top-[-42px] md:top-[-40px] left-[9em] md:left-[4em]"></div>
        </div>
        <div className="flex md:hidden items-center md:items-start justify-center flex-col w-full pl-12">
          <h2 className="font-bold text-2xl md:text-3xl py-2">Become a companion</h2>
          <p className="text-sm md:text-[15px] text-center md:text-start w-[25em] my-3 md:my-8">
            Make a difference. Help in caring and engaging with older adults
            while earning an income.
          </p>
          <Link
            to="become-companion"
            className="text-center my-3 text-sm bg-[#038aae] text-white px-2 py-3 w-32 rounded-lg"
          >
            Start Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Explore;
