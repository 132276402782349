import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import { useLogin } from "../hooks/useLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { handleApiError } from "../api/api";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from context
  const {
    email,
    handleEmailChange,
    handleEmailBlur,
    emailError,
    password,
    passwordError,
    handlePasswordBlur,
    handlePasswordChange,
    showPassword,
    handleShowPasswordClick,
  } = useLogin();

  const [error, setError] = useState(""); // State for error message
  const apiUrl = `${process.env.REACT_APP_AUTH_API_URL}Authenticate`;

  const handleLogin = async () => {
    try {
      const response = await axios.post(apiUrl, {
        username: email,
        password,
      });

      // Parse the user data from the response
      const userData = response.data;
      console.log({ userData });

      // Use the login function from context to set the authentication state
      login(userData);

      // Check the role in the response and navigate accordingly
      if (response.data.role === "Companion") {
        if (response.data.pageNumber === 5) {
          navigate("/registration-completed");
        } else {
          navigate("/companion-registration");
        }
      } else if (response.data.role === "Senior") {
        navigate("/senior-portal");
      }
      window.scrollTo(0, 0);
    } catch (error: any) {
      handleApiError(error);
      console.error("Error:", error);
      if (error.response && error.response.status === 400) {
        setError("Invalid credentials");
      }
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="mt-[88px] w-[400px] sm:w-[500px] mx-auto py-6 sm:px-0 px-2">
        <div className="py-4 text-center">
          <h2 className="py-2 text-4xl font-bold">Login</h2>
          <p className="py-3 max-w-[500px] mx-auto text-lg">
            Input your details to proceed
          </p>
        </div>
        <div className="py-3">
          <div className="flex flex-col w-full">
            <Input
              label="Email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              type="email"
              placeholder="Email Address"
            />
            {emailError && (
              <span className="text-red-500 text-sm">{emailError}</span>
            )}
          </div>
          <div className="flex flex-col w-full">
            <div className="relative">
              <Input
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
              />
              <div
                className="absolute bottom-[-6px] right-1 transform -translate-y-1/2 cursor-pointer"
                onClick={handleShowPasswordClick}
              >
                <div className={`w-8 h-8 bg-white text-[#038aae]}`}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </div>
              </div>
            </div>
            {/* {passwordError && (
              <span className="text-red-500 text-sm">{passwordError}</span>
            )} */}
          </div>
        </div>
        {error && <div className="text-red-500 text-center py-2">{error}</div>}
        <div className="flex item-center justify-center">
          <button
            onClick={handleLogin}
            disabled={!!emailError}
            className={`py-2 px-4 text-white bg-[#038aae] rounded-lg w-[150px] mx-auto`}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
