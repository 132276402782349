import React from "react";

const Youtube = () => {
  return (
    <div className="my-6 py-8">
      <iframe
        className="w-full md:w-[900px] px-4 md:mx-auto h-[500px]"
        width="420"
        height="615"
        src="https://www.youtube.com/embed/iF1_7Vp5sSw"
      ></iframe>{" "}
    </div>
  );
};

export default Youtube;
