import React from "react";
const companionImage = require("../images/imageforaboutus.jpg");
const companionAim = require("../images/companionaim.jpg");

const AboutUs = () => {
  return (
    <div className="py-8 mt-[55px]">
      <div className="text-center py-3 mt-12">
        <h2 className="text-5xl font-bold text-[#038aae]">About Us</h2>
        <h2 className="text-2xl mt-3">Engageful Companionship</h2>
      </div>
      <div className="flex flex-col flex-col-reverse lg:flex-row items-center lg:items-center justify-between p-8 lg:py-16 gap-8 max-w-7xl px-4 mx-auto">
        {/* Text Section */}
        <div className="lg:w-1/2">
          <h2 className="text-3xl text-[#038aae] font-bold">
            Inspired by a compassionate care approach
          </h2>
          <p className="text-lg mt-4 text-gray-600">
            This solution was unveiled by nurses who work in long-term care
            facilities and are passionate in helping seniors age in place. While
            working at long-term care facilities, it became evident that some
            seniors were becoming socially isolated.
          </p>
          <p className="text-lg mt-4 text-gray-600">
            Our team identified this gap that existed when it came to supporting
            seniors, especially those of diverse ethnocultural backgrounds.
            Thus, we decided to bring our efforts together to support the future
            of aging seniors. We saw the need and we responded to the call!
          </p>
        </div>

        {/* Image Section */}
        <div className="lg:w-1/2 flex justify-center">
          <img
            src={companionImage}
            alt="Compassionate care"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center lg:items-center justify-between p-8 lg:py-16 gap-8 max-w-7xl px-4 mx-auto my-6">
        {/* Image Section */}
        <div className="lg:w-1/2 flex justify-center">
          <img
            src={companionAim}
            alt="Compassionate care"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="lg:w-1/2">
          <h2 className="text-3xl text-[#038aae] font-bold">Our Aim</h2>
          <p className="text-lg mt-4 text-gray-600">
            We aim to provide consistent social engagement by promoting a
            culture of companionship within long term care facilities. This
            fosters the psychological well-being of seniors through a culturally
            nurtured environment.
          </p>
          <p className="text-lg mt-4 text-gray-600">
            Our solution creates trustworthy services by streamlining
            communication between the family, the facility, recreational team
            and the companion.
          </p>
        </div>
      </div>

      <div className="text-center max-w-7xl mx-auto my-4">
        <h2 className="text-3xl py-6 text-[#038aae] font-bold">
          Why psychological well-being?
        </h2>
        <p className="text-lg px-4 lg:w-[1000px] mx-auto">
          Social engagement in long term care is a top priority in determining
          the quality of life among older adults. Studies have shown that about
          50% of seniors score low on the index of social engagement (ISE), a
          metric used to gauge their psychological well-being. Lower scores have
          been associated with emotional distress in seniors, this typically
          presents as depression, agitation, wandering and hitting out.
        </p>
      </div>

      <div className="text-center max-w-7xl mx-auto my-8">
        <h2 className="text-3xl py-6 text-[#038aae] font-bold">
          Companion vs Caregiver
        </h2>
        <p className="text-lg px-4 lg:w-[1000px] mx-auto">
          Companion care is a type of non-medical care provided at home to
          seniors or those with disabilities. A companion typically provides
          emotional support and can assist with everyday tasks such as preparing
          meals and housekeeping. The main focus of a Caregiver is to assist
          with tasks involving personal hygiene, toileting, and other personal
          tasks. Typically, caregiver/personal support workers require a degree
          of medical training.
        </p>
      </div>

      <div className="text-center max-w-7xl mx-auto my-4">
        <h2 className="text-3xl py-6 text-[#038aae] font-bold">In summary</h2>
        <p className="text-lg px-4 lg:w-[1000px] mx-auto">
          We see the positive difference that companions make among seniors
          helping them happily age in place. Seniors are never alone, the
          companion always articulates the needs of the senior and keeps them
          engaged.
        </p>
      </div>

      <div className="text-center max-w-7xl mx-auto my-4">
        <h2 className="text-3xl py-6 text-[#038aae] font-bold">
          Privacy Policy
        </h2>
        <p className="text-lg px-4 lg:w-[1000px] mx-auto">
          We value your privacy and we are taking the necessary precautions to
          be in compliance with data regulation. Our Privacy Policy is in
          progress and will be uploaded here as soon as it is completed.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
