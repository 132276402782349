import React, { useState, useEffect } from "react";
import MultiStep from "./Multistep";
import Step1 from "./StepOne";
import Step2 from "./StepTwo";
import { useNavigate } from "react-router-dom";
import Step3 from "./StepThree";
import Step4 from "./StepFour";
import { useAuth } from "../context/AuthContext";

const MultiStepRegistration: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4;
  const token = sessionStorage.getItem("token");
  const { isLoggedIn } = useAuth(); 

  const navigate = useNavigate();

  useEffect(() => {
    // If user is not logged in, redirect to login
    // if (!isLoggedIn) {
    //   navigate("/login");
    //   return;
    // }

    // Retrieve page from sessionStorage
    const page = sessionStorage.getItem("page") || sessionStorage.getItem("pageNumber");
    // Parse the value to an integer and check if it's within the valid range of steps
    const parsedPage = parseInt(page!);
    if (!isNaN(parsedPage) && parsedPage >= 1 && parsedPage <= totalSteps) {
      setCurrentStep(parsedPage);
    }
  }, [ totalSteps]);

  const handleNextStep = () => {
    // Increment the current step
    setCurrentStep((prevStep) => {
      const nextStep = prevStep + 1;
      // Save the next step to sessionStorage
      sessionStorage.setItem("page", nextStep.toString());
      // Scroll to top
      window.scrollTo(0, 0);
      return nextStep;
    });
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => {
      const updatedStep = prevStep - 1;
      // Save the updated step to sessionStorage
      sessionStorage.setItem("page", updatedStep.toString());
      // Scroll to top
      window.scrollTo(0, 0);
      return updatedStep;
    });
  };
  
  return (
    <div className="mt-[88px] w-full bg-gray-100">
      <MultiStep totalSteps={totalSteps} currentStep={currentStep} />

      {/* Render current step/page */}
      <div className="flex items-center justify-center w-full py-4">
        {currentStep === 1 && <Step1 onNext={handleNextStep} />}
        {currentStep === 2 && (
          <Step2 onPrev={handlePrevStep} onNext={handleNextStep} />
        )}
        {currentStep === 3 && (
          <Step3 onPrev={handlePrevStep} onNext={handleNextStep} />
        )}
        {currentStep === 4 && <Step4 onNext={handleNextStep} />}
      </div>
    </div>
  );
};

export default MultiStepRegistration;
