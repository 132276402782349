import axios from 'axios';
import { handleApiError } from '../api';

interface City {
  cityID: number;
  cityName: string;
}

const getCitiesByRegionId = async (regionId: number): Promise<City[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}getCitiesByRegionId/${regionId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw new Error('Failed to fetch regions');
  }
};

export default getCitiesByRegionId;

export const getCities = async() => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}GetCities`);
    return response.data;
  }
  catch (error) {
    handleApiError(error);
    throw new Error("Failled to fetch cities")
  }
}