import React, { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
const Smiley = require("../images/smiley.png");

const RegistrationCompleted = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }
  });
  return (
    <div className="mt-[90px] text-center w-[400px] mx-auto h-[50vh] rounded-lg shadow-xl flex items-center justify-center bg-gray-50 mb-4">
      <div className="flex flex-col">
        <img src={Smiley} alt="Smiley" />
        <h2 className="py-4 text-2xl px-2">
          Your onboarding process has been completed, please proceed to{" "}
          <a className="text-[#038aae]" href="https://portal.linkrx.ca/">
            your portal
          </a>
          .
        </h2>
      </div>
    </div>
  );
};

export default RegistrationCompleted;
