// components/Loader.tsx
import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center z-50">
      <div className="loader border-t-4 border-b-4 border-[#038aae] rounded-full w-12 h-12 animate-spin"></div>
    </div>
  );
};

export default Loader;
