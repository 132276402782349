import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../api/api";

interface ServiceRequest {
  serviceRequestID: number;
  companionID: number;
  seniorID: number;
  isDeleted: boolean;
  serviceStartDateTime: string;
  serviceNumberOfHoursExtended: number;
  serviceNumberOfHoursEnd: number;
  serviceRequestUniqueCode: string;
  noOfHours: string;
  serviceRequestName: string;
  serviceRequestUniqueCodeStatusName: string;
  serviceRequestUniqueCodeDescription: string;
  introText: string;
  serviceStatus: string;
  requesterPreference: string;
  requester: string;
  companion: string;
  dateAdded: string;
  bookedByAdmin: boolean;
  adminEmail: string;
  serviceRequestStatusID: number;
}

interface ApiResponse {
  data: ServiceRequest[];
  totalPages: number;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

const SeniorPortal: React.FC = () => {
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState("ServiceRequests");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const seniorID = 40;
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    // If token is not present, redirect to login
    if (!token && role !== "Senior") {
      navigate("/login");
      return;
    }
  }, [token, navigate]);

  const fetchData = async (
    pageNumber: number,
    startDate?: Date | null,
    endDate?: Date | null
  ) => {
    try {
      let url = `${process.env.REACT_APP_API_SERVICES_URL}ServiceRequest/Senior/${seniorID}?pageNumber=${pageNumber}&pageSize=10`;
      if (startDate && endDate) {
        const start = startDate.toISOString();
        const end = endDate.toISOString();
        url += `&startDate=${start}&endDate=${end}`;
      }
      const response: AxiosResponse<ApiResponse> = await axios.get(url);
      setServiceRequests(response.data.data);
      setTotalPages(response.data.totalPages);
      console.log({ response });
    } catch (error) {
      handleApiError(error);
      console.error("Error fetching service requests:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [seniorID, currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchData(currentPage - 1, startDate, endDate);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchData(currentPage + 1, startDate, endDate);
    }
  };

  const handleFilter = () => {
    fetchData(1, startDate, endDate);
  };

  return (
    <div className="mt-[88px] flex flex-col lg:flex-row min-h-screen">
      <div className="bg-[#038aae] text-white w-full lg:w-[20%] py-4">
        <h2 className="text-xl mb-4">Dashboard</h2>
        <ul className="flex flex-col gap-3">
          <li
            className={`p-2 cursor-pointer ${
              activeTab === "ServiceRequests" ? "bg-[#036b84]" : ""
            }`}
            onClick={() => setActiveTab("ServiceRequests")}
          >
            Service Requests
          </li>
          {/* Add more sidebar items here as needed */}
        </ul>
      </div>
      <div className="w-full lg:w-[80%]">
        {activeTab === "ServiceRequests" && (
          <div className="text-center mx-auto max-w-7xl">
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date: React.SetStateAction<Date | null>) =>
                    setStartDate(date)
                  }
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="border p-2 rounded"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date: React.SetStateAction<Date | null>) =>
                    setEndDate(date)
                  }
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  className="border p-2 rounded"
                />
                <button
                  onClick={handleFilter}
                  className="px-4 py-2 bg-[#036b84] text-white rounded"
                >
                  Filter
                </button>
              </div>
            </div>
            {serviceRequests.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead className="bg-[#038aae] text-white">
                    <tr>
                      <th className="px-4 py-2">Request ID</th>
                      <th className="px-4 py-2">Requester</th>
                      <th className="px-4 py-2">Companion</th>
                      <th className="px-4 py-2">Service Start</th>
                      <th className="px-4 py-2">Hours</th>
                      <th className="px-4 py-2">Status</th>
                      <th className="px-4 py-2">Preference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceRequests.map((request) => (
                      <tr key={request.serviceRequestID} className="border-t">
                        <td className="px-4 py-2">
                          {request.serviceRequestID}
                        </td>
                        <td className="px-4 py-2">{request.requester}</td>
                        <td className="px-4 py-2">{request.companion}</td>
                        <td className="px-4 py-2">
                          {new Date(
                            request.serviceStartDateTime
                          ).toLocaleString()}
                        </td>
                        <td className="px-4 py-2">{request.noOfHours}</td>
                        <td className="px-4 py-2">
                          {request.serviceRequestName}
                        </td>
                        <td className="px-4 py-2">
                          {request.requesterPreference}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-4 flex justify-center items-center">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 mx-2 border rounded disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 mx-2 border rounded disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SeniorPortal;
