import React from "react";
const icon1 = require("../../assets/icons/icon1.png");
const icon2 = require("../../assets/icons/icon2.png");
const icon3 = require("../../assets/icons/icon3.png");
const icon4 = require("../../assets/icons/icon4.png");

const OurServices = () => {
  return (
    // <div className="bg-[#038aae30]">
    <div className="max-w-7xl mx-auto my-4 px-4">
      <div className="flex item-start justify-center flex-col mt-8 mb-14">
        <div className="font-bold text-4xl py-3 mx-auto">
          Our Services
        </div>
        <div className="bg-[#038aae] h-1 w-40 mx-auto relative left-[-25px]"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-4 px-4 md:px-0">
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon4} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">Technology Use</h1>
          <div className="text-sm text-gray-500">
            LinkRx companions help older adults navigate gadget use and virtual
            visits for easy connection with the families. This includes gaming
            and other brain stimulation activities.
          </div>
        </div>
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon2} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">Culture and Value support</h1>
          <div className="text-sm text-gray-500">
            LinkRx respects older adults' culture and values and strives to
            support any activities in this realm that the senior is interested
            in e.g. Church services, indigenous activities etc.
          </div>
        </div>
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon1} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">
            Social Visit and Engagement
          </h1>
          <div className="text-sm text-gray-500">
            LinkRx companions will visit your loved ones engage them in
            meaningful conversations as well as past reminiscence to find out
            more about things they like (hobbies).
          </div>
        </div>
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon4} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">Daily Walks</h1>
          <div className="text-sm text-gray-500">
            LinkRx companions help seniors explore outdoor sceneries, reconnect
            with nature and promote physical well-being.
          </div>
        </div>
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon2} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">
            Light Meal Preparation and Feeding
          </h1>
          <div className="text-sm text-gray-500">
            LinkRx assists with preparing light snacks for your loved ones as
            well providing extra time to support with feeding during meal times.
          </div>
        </div>
        <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
          <div className="bg-[#038aae] p-2">
            <img src={icon1} alt="" />
          </div>
          <h1 className="py-3 text-lg font-bold ">Medical Trips</h1>
          <div className="text-sm text-gray-500">
            LinkRx will support you to take your loved ones to dental and
            specialist appointments so as to free up your time for quality
            visits.
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default OurServices;
