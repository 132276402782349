import React, { useState, useEffect } from 'react';

interface FileUploadProps {
  onFileSelect: (file: File) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileSelect }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | undefined>(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      onFileSelect(file);
      setFilePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    // Clean up URL object to avoid memory leaks
    return () => {
      if (filePreview) {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);

  return (
    <div className="flex flex-col items-center gap-2 w-[400px] sm:w-[500px]">
      <input
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        onChange={handleFileChange}
        className="py-2 px-4 border border-gray-300 rounded-lg w-[400px] sm:w-[500px]"
      />
      {selectedFile && selectedFile.type.startsWith('image/') && (
        <img
          src={filePreview}
          alt="Selected"
          className="max-h-40 max-w-full"
        />
      )}
      {selectedFile && selectedFile.type === 'application/pdf' && (
        <iframe
          src={filePreview}
          title="Selected PDF"
          className="w-full h-40"
        />
      )}
    </div>
  );
};

export default FileUpload;
