import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

interface AvailabilityItem {
  availableDays: string;
  availabilityStartTime: string;
  availabilityEndTime: string;
}

interface AvailabilityProps {
  availability: AvailabilityItem[];
}

const CalendarAvailability: React.FC<AvailabilityProps> = ({ availability }) => {
  const hasAvailability = availability.length > 0;
  const hasDays = availability.some(
    (item) => item.availableDays && item.availableDays.length > 0
  );
  const hasTimes = availability.some(
    (item) => item.availabilityStartTime && item.availabilityEndTime
  );

  if (!hasAvailability || !hasDays || !hasTimes) {
    return (
      <div className="text-gray-600">No availability information provided.</div>
    );
  }

  const events = availability.flatMap((item) => {
    const days = item.availableDays.split(",");
    return days.map((day) => {
      const start = moment(
        `${day.trim()} ${item.availabilityStartTime}`,
        "dddd HH:mm"
      ).toDate();
      const end = moment(
        `${day.trim()} ${item.availabilityEndTime}`,
        "dddd HH:mm"
      ).toDate();
      return {
        title: `Available`,
        start: start,
        end: end,
        allDay: false,
      };
    });
  });

  return (
    <div className="calendar-wrapper">
      <div className="calendar-container">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 400, width: "100%" }}
          views={['day', 'month', 'agenda']}
          defaultView="month" // Set default view to 'day' or another view
        />
      </div>
    </div>
  );
};

export default CalendarAvailability;