import React from "react";
const icon1 = require("../assets/icons/icon1.png");
const icon2 = require("../assets/icons/icon2.png");
const icon3 = require("../assets/icons/icon3.png");
const icon4 = require("../assets/icons/icon4.png");
const serviceimageone = require("../images/servicesimage1.jpg");
const carefeedback = require("../images/carefeedback.jpg");
const Explore1 = require("../images/explore1.jpg");

const Services = () => {
  return (
    <div className="py-8 mt-[55px]">
      <div className="max-w-7xl mx-auto my-4 px-4">
        <div className="flex item-start justify-center flex-col mt-8 mb-8">
          <div className="font-bold text-4xl py-3 mx-auto">Our Services</div>
          <div className="bg-[#038aae] h-1 w-40 mx-auto relative left-[-25px]"></div>
        </div>
        <h2 className="text-center pb-6">
          We aim to fulfill the umeet needs of your loved one. We help the older
          adults in care facilities navigate the environment with ease and have
          an engaged life.Families can have peace of mind that the seniors are
          with trust worthy companions, vetted and hired within the facility.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-4 px-4 md:px-0">
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon4} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">Social Visits</h1>
            <div className="text-sm text-gray-500">
              LinkRx companions spend quality time with your loved ones. We
              understand that as a caregiver you have other responsibilities and
              we can support you by being there for your loved one.
            </div>
          </div>
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon2} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">Tech Support</h1>
            <div className="text-sm text-gray-500">
              LinkRx companions help older adults navigate gadget use and
              virtual visits for easy connection with their families. This
              includes gaming and other brain stimulation activities.
            </div>
          </div>
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon1} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">
              Culture and Value Support
            </h1>
            <div className="text-sm text-gray-500">
              LinkRx respects older adults' culture and values and strives to
              support any activities in the realm that the senior is interested
              in, e.g. church services, indigenous activities etc.
            </div>
          </div>
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon4} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">Meal Time Assistance</h1>
            <div className="text-sm text-gray-500">
            LinkRx provides companions to support your loved ones during meal times as per the facility policy.
            </div>
          </div>
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon2} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">Medical Trips</h1>
            <div className="text-sm text-gray-500">
              LinkRx will support you to take your loved ones to dental and
              specialist appointments so as to free up your time for quality
              visits.
            </div>
          </div>
          <div className="service-card rounded-lg cursor-pointer p-4 flex items-start flex-col justify-center">
            <div className="bg-[#038aae] p-2">
              <img src={icon1} alt="" />
            </div>
            <h1 className="py-3 text-lg font-bold ">Daily Walks</h1>
            <div className="text-sm text-gray-500">
              LinkRx companions help seniors explore outdoor sceneries,
              reconnect with nature and promote physical well-being.
            </div>
          </div>
        </div>

        <h2 className="text-4xl text-center text-[#038aae] mt-16 font-bold">
        More Of Our Services Include
        </h2>
        <div className="flex flex-col lg:flex-row items-center lg:items-center justify-between p-8 lg:py-16 gap-8 max-w-7xl px-4 mx-auto">
          {/* Image Section */}
          <div className="lg:w-1/2 flex justify-center">
            <img
              src={Explore1}
              alt="Compassionate care"
              className="w-auto lg:h-[800px] object-cover rounded-lg shadow-lg"
            />
          </div>
          {/* Text Section */}
          <div className="lg:w-1/2 flex flex-col gap-8">
            <div className="">
              <h2 className="text-3xl text-[#038aae] font-bold">
                Cognitive Stimulation Activities
              </h2>
              <p className="text-lg mt-2 text-gray-600">
                LinkRx helps improve cognitive function by encouraging seniors
                to participate more in activities that stimulate brain function
                such as card games, reading, puzzles and even crafts.
              </p>
            </div>
            <div className="">
              <h2 className="text-3xl text-[#038aae] font-bold">
                Medication Compliance
              </h2>
              <p className="text-lg mt-2 text-gray-600">
                LinkRx companions build great rapport with the seniors which in
                turn helps with medication compliance, especially clients with
                dementia.LinkRx companions build great rapport with the seniors
                which in turn helps with medication compliance, especially
                clients with dementia.
              </p>
            </div>
            <div className="">
              <h2 className="text-3xl text-[#038aae] font-bold">Light ADLs</h2>
              <p className="text-lg mt-2 text-gray-600">
                LinkRx supports you when it comes to receiving more one on one
                care with seniors, for example with behavioral management,
                Assistance with Bathing/Self Care, e.t.c.
              </p>
            </div>
            <div className="">
              <h2 className="text-3xl text-[#038aae] font-bold">
                Culture and Value support
              </h2>
              <p className="text-lg mt-2 text-gray-600">
                LinkRx respects older adults' culture and values and strives to
                support any activities in this realm that the senior is
                interested in e.g. Church services, indigenous activities etc.
              </p>
            </div>
            <div className="">
              <h2 className="text-3xl text-[#038aae] font-bold">
                Therapy support
              </h2>
              <p className="text-lg mt-2 text-gray-600">
                We aim to support our seniors with different modalities of
                therapy. Animal therapy, music, art therapy and modern themes
                like VR therapy.
              </p>
            </div>
          </div>
        </div>

        <h2 className="text-4xl text-center text-[#038aae] mt-12 font-bold">
          Feedback
        </h2>
        <div className="flex flex-col flex-col-reverse lg:flex-row items-center lg:items-center justify-between p-8 lg:py-16 gap-8 max-w-7xl px-4 mx-auto">
          {/* Text Section */}
          <div className="lg:w-1/2">
            <h2 className="text-3xl text-[#038aae] font-bold">
              Inspired by a compassionate care approach
            </h2>
            <p className="text-lg mt-4 text-gray-600">
              This solution was unveiled by nurses who work in long-term care
              facilities and are passionate in helping seniors age in place.
              While working at long-term care facilities, it became evident that
              some seniors were becoming socially isolated.
            </p>
            <p className="text-lg mt-4 text-gray-600">
              Our team identified this gap that existed when it came to
              supporting seniors, especially those of diverse ethnocultural
              backgrounds. Thus, we decided to bring our efforts together to
              support the future of aging seniors. We saw the need and we
              responded to the call!
            </p>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 flex justify-center">
            <img
              src={carefeedback}
              alt="Compassionate care"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
