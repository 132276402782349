import React, { useState } from "react";
import Input from "./Input";
import FileUpload from "./FileUpload";
import { AddCredibilityWithUpload, handleApiError } from "../api/api";

import Modal from "../components/SuccessModal";
import { useLogin } from "../hooks/useLogin";
import { useAuth } from "../context/AuthContext";

interface Step4Props {
  onNext: () => void;
}

const Step4: React.FC<Step4Props> = ({ onNext }) => {
  const { logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [driverLiscenseFile, setDriverLiscenseFile] = useState<File | null>(
    null
  );
  const [criminalRecordFile, setCriminalRecordFile] = useState<File | null>(
    null
  );

  // State for each input field
  const [firstReferenceFirstName, setFirstReferenceFirstName] = useState("");
  const [firstReferenceLastName, setFirstReferenceLastName] = useState("");
  const [firstReferencePhoneNumber, setFirstReferencePhoneNumber] =
    useState("");
  const [firstReferenceEmail, setFirstReferenceEmail] = useState("");
  const [firstReferencePosition, setFirstReferencePosition] = useState("");

  const [secondReferenceFirstName, setSecondReferenceFirstName] = useState("");
  const [secondReferenceLastName, setSecondReferenceLastName] = useState("");
  const [secondReferencePhoneNumber, setSecondReferencePhoneNumber] =
    useState("");
  const [secondReferenceEmail, setSecondReferenceEmail] = useState("");
  const [secondReferencePosition, setSecondReferencePosition] = useState("");

  const [thirdReferenceFirstName, setThirdReferenceFirstName] = useState("");
  const [thirdReferenceLastName, setThirdReferenceLastName] = useState("");
  const [thirdReferencePhoneNumber, setThirdReferencePhoneNumber] =
    useState("");
  const [thirdReferenceEmail, setThirdReferenceEmail] = useState("");
  const [thirdReferencePosition, setThirdReferencePosition] = useState("");

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<any>(null);

  const { emailError, phoneNumberError } = useLogin();

  const handleDriverIDUpload = (driverFile: File) => {
    setDriverLiscenseFile(driverFile);
  };

  const handleCriminalRecordUpload = (criminalRecord: File) => {
    setCriminalRecordFile(criminalRecord);
  };

  const handleError = (error: any) => {
    setError(error);
  };

  // const pageCount = 5;

  const formPayload = new FormData();

  // Append reference 1 data
  formPayload.append("firstReferenceFirstName", firstReferenceFirstName);
  formPayload.append("firstReferenceLastName", firstReferenceLastName);
  formPayload.append("firstReferenceEmail", firstReferenceEmail);
  formPayload.append("firstReferencePhoneNumber", firstReferencePhoneNumber);
  formPayload.append("firstReferencePosition", firstReferencePosition);

  // Append reference 2 data
  formPayload.append("secondReferenceFirstName", secondReferenceFirstName);
  formPayload.append("secondReferenceLastName", secondReferenceLastName);
  formPayload.append("secondReferencePhoneNumber", secondReferencePhoneNumber);
  formPayload.append("secondReferenceEmail", secondReferenceEmail);
  formPayload.append("secondReferencePosition", secondReferencePosition);

  // Append reference 3 data
  formPayload.append("thirdReferenceFirstName", thirdReferenceFirstName);
  formPayload.append("thirdReferenceLastName", thirdReferenceLastName);
  formPayload.append("thirdReferenceEmail", thirdReferenceEmail);
  formPayload.append("thirdReferencePhoneNumber", thirdReferencePhoneNumber);
  formPayload.append("thirdReferencePosition", thirdReferencePosition);

  // Append file uploads
  if (criminalRecordFile) {
    formPayload.append("criminalRecordCheckDocument", criminalRecordFile);
  }
  if (driverLiscenseFile) {
    formPayload.append("driverLicenceDocument", driverLiscenseFile);
  }

  // Append other data
  formPayload.append("passInterview", "false");
  formPayload.append("completeCriminalRecordCheck", "false");
  formPayload.append("completeReferenceCheck", "false");
  formPayload.append("companionID", `${sessionStorage.getItem("companionId")}`);
  const pageCount = 5;

  const handleNextClick = async () => {
    const companionID = sessionStorage.getItem("companionId");
    if (!companionID) {
      console.error("Companion ID is not available.");
      return;
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is not available.");
      return;
    }

    try {
      setLoading(true);
      await AddCredibilityWithUpload(formPayload, token, pageCount).then(
        (res) => {
          sessionStorage.removeItem("page");
        }
      );
      setLoading(false);
      sessionStorage.removeItem("page");
      logout();
      // sessionStorage.setItem("page", `${pageCount}`);
      setShowModal(true);

      // onNext();
    } catch (error) {
      handleApiError(error);
      setLoading(false);
      handleError(error);
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const isFormValid = () => {
    // Check if all required fields have been filled
    const requiredFields = [
      firstReferenceFirstName,
      firstReferenceLastName,
      firstReferencePhoneNumber,
      firstReferenceEmail,
      firstReferencePosition,
      // secondReferenceFirstName,
      // secondReferenceLastName,
      // secondReferencePhoneNumber,
      // secondReferenceEmail,
      // secondReferencePosition,
      // thirdReferenceFirstName,
      // thirdReferenceLastName,
      // thirdReferencePhoneNumber,
      // thirdReferenceEmail,
      // thirdReferencePosition,
    ];

    const allFieldsFilled = requiredFields.every((field) => field !== "");

    // Check if driver's license file has been uploaded
    const driverLicenseUploaded = driverLiscenseFile !== null;

    return allFieldsFilled && driverLicenseUploaded;
  };

  const handleFirstPhoneNumberChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setFirstReferencePhoneNumber(value);
  };

  return (
    <div className="w-[400px] sm:w-[500px]">
      {showModal && (
        <Modal
          closeModal={closeModal}
          link="https://portal.LinkRx.ca/Companion/Settings/Screening"
        >
          <p>
            Your details have been successfully captured. For the safety of our
            seniors, please click the 'Proceed' button to navigate to your
            dashboard and upload your work permit and resume. Our team will
            review your information, and activate your account upon
            verification.
          </p>
        </Modal>
      )}
      <div className="flex flex-col gap-1">
        <h2 className="py-2 text-lg text-center">
          Upload Driver's License or Photo ID
        </h2>
        <FileUpload onFileSelect={handleDriverIDUpload} />
      </div>
      <div className="flex flex-col gap-2 my-6">
        <h2 className="text-lg text-center">Upload Criminal Record</h2>
        <p className="text-gray-500 text-center max-w-[500px] text-md">
          Mandatory before first shift (optional now if not at hand)
        </p>
        <FileUpload onFileSelect={handleCriminalRecordUpload} />
      </div>
      <div className="my-2">
        <div className="text-center flex flex-col gap-2">
          <p className="text-gray-500 text-center max-w-[500px] text-md">
            Provide a Minimum of Two (2) References
          </p>
          <h2 className=" text-xl">Reference 1</h2>
        </div>
        <Input
          label="First Name"
          placeholder="First Name"
          type="text"
          value={firstReferenceFirstName}
          onChange={(e) => setFirstReferenceFirstName(e.target.value)}
        />
        <Input
          label="Last Name"
          placeholder="Last Name"
          type="text"
          value={firstReferenceLastName}
          onChange={(e) => setFirstReferenceLastName(e.target.value)}
        />
        <Input
          label="Phone Number"
          placeholder="Phone Number"
          type="text"
          value={firstReferencePhoneNumber}
          onChange={(e) => setFirstReferencePhoneNumber(e.target.value)}
        />
        <Input
          label="Email Address"
          placeholder="Email Address"
          type="email"
          value={firstReferenceEmail}
          onChange={(e) => setFirstReferenceEmail(e.target.value)}
        />
        <Input
          label="Position"
          placeholder="Position (e.g Manager)"
          type="text"
          value={firstReferencePosition}
          onChange={(e) => setFirstReferencePosition(e.target.value)}
        />

        {/* Reference 2 */}
        <h2 className=" text-xl py-2">
          Reference 2 <span className="text-[#038aae] font-bold">(optional)</span>
        </h2>
        <Input
          label="First Name"
          placeholder="First Name"
          type="text"
          value={secondReferenceFirstName}
          onChange={(e) => setSecondReferenceFirstName(e.target.value)}
        />
        <Input
          label="Last Name"
          placeholder="Last Name"
          type="text"
          value={secondReferenceLastName}
          onChange={(e) => setSecondReferenceLastName(e.target.value)}
        />
        <div className="flex flex-col w-full">
          <Input
            label="Phone Number"
            placeholder="Phone Number"
            type="text"
            value={secondReferencePhoneNumber}
            onChange={(e) => setSecondReferencePhoneNumber(e.target.value)}
          />
          {phoneNumberError && (
            <span className="text-red-500 text-sm">{phoneNumberError}</span>
          )}
        </div>
        <div className="flex flex-col w-full">
          <Input
            label="Email Address"
            placeholder="Email Address"
            type="email"
            value={secondReferenceEmail}
            onChange={(e) => setSecondReferenceEmail(e.target.value)}
          />
          {emailError && (
            <span className="text-red-500 text-sm">{emailError}</span>
          )}
        </div>
        <Input
          label="Position"
          placeholder="Position"
          type="text"
          value={secondReferencePosition}
          onChange={(e) => setSecondReferencePosition(e.target.value)}
        />

        {/* Reference 3 */}
        <h2 className=" text-xl py-2">
          Reference 3 <span className="text-[#038aae] font-bold">(optional)</span>
        </h2>
        <Input
          label="First Name"
          placeholder="First Name"
          type="text"
          value={thirdReferenceFirstName}
          onChange={(e) => setThirdReferenceFirstName(e.target.value)}
        />
        <Input
          label="Last Name"
          placeholder="Last Name"
          type="text"
          value={thirdReferenceLastName}
          onChange={(e) => setThirdReferenceLastName(e.target.value)}
        />
        <Input
          label="Phone Number"
          placeholder="Phone Number"
          type="text"
          value={thirdReferencePhoneNumber}
          onChange={(e) => setThirdReferencePhoneNumber(e.target.value)}
        />
        <Input
          label="Email Address"
          placeholder="Email Address"
          type="email"
          value={thirdReferenceEmail}
          onChange={(e) => setThirdReferenceEmail(e.target.value)}
        />
        <Input
          label="Position"
          placeholder="Position"
          type="text"
          value={thirdReferencePosition}
          onChange={(e) => setThirdReferencePosition(e.target.value)}
        />
      </div>
      <button
        onClick={handleNextClick}
        disabled={!isFormValid()}
        className={`rounded-lg ${
          !isFormValid() || loading
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-gradient-to-br from-[#038aae] to-[#038aae90]"
        } text-white px-3 py-2 mb-4`}
      >
        {loading ? "Loading..." : "Next"}
      </button>
    </div>
  );
};

export default Step4;
