import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
const Logo = require("../../images/logo.png");

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();

  // useEffect(() => {
  //   // Check if token exists in session storage
  //   const token = sessionStorage.getItem("token");
  //   if (token) {
  //     setIsLoggedIn(true);
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    // Clear entire session storage
    sessionStorage.clear();
    // setIsLoggedIn(false);
  };

  const handleSeniorPortalClick = () => {
    const token = sessionStorage.getItem("token");
    const role = sessionStorage.getItem("role");

    setIsDropdownOpen(false)

    if (role === "Senior") {
      console.log(role);
      setIsOpen(false);
      navigate("/senior-portal");
    } else {
      setIsOpen(false);
      navigate("/login");
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white/40 text-black py-3 fixed top-0 w-full z-[9999] backdrop-blur">
      <div className="md:max-w-7xl mx-auto px-6">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex">
            <img src={Logo} alt="hi" className="h-12" />
          </Link>
          <div className="hidden sm:hidden lg:flex items-center">
            <div className="ml-4 flex items-center md:ml-6">
              {!isLoggedIn && (
                <Link
                  to="find-companion"
                  className="text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
                >
                  View companions
                </Link>
              )}
              {!isLoggedIn && (
                <Link
                  to="become-companion"
                  className="text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
                >
                  Become a companion
                </Link>
              )}

              <Link
                to="/about-us"
                className="text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
              >
                About Us
              </Link>
              <Link
                to="/our-services"
                className="text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
              >
                Our Services
              </Link>
              {/* <Link
                to="https://docs.google.com/forms/d/1Jx8ImcZcoNEVQ3mxifi464Uj3k9oCjfhbv5KuUaft2k/prefill"
                target="_blank"
                className="text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
              >
                Intake Form
              </Link> */}
            </div>
          </div>
          <div className="hidden sm:hidden lg:flex gap-4 items-center">
            <div className="relative">
              <div className="flex items-center justify-center border border-[#038aae] p-2 gap-2 rounded-lg w-32">
                <button onClick={toggleDropdown} className="text-black">
                  My Portal
                </button>
                <FontAwesomeIcon icon={faCaretDown} />
              </div>

              {isDropdownOpen && (
                <div className="absolute mt-2 w-32 text-lg rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <Link
                    to="https://portal.linkrx.ca/"
                    target="_blank"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Companion
                  </Link>
                  <button
                    onClick={handleSeniorPortalClick}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Senior
                  </button>
                  <Link
                    to={`${process.env.REACT_APP_FACILITY_PORTAL}`}
                    target="_blank"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Facility
                  </Link>
                </div>
              )}
            </div>
            <div className="">
              {isLoggedIn ? (
                <button
                  onClick={logout}
                  className="flex items-center justify-center rounded-lg bg-gradient-to-br from-[#038aae] to-[#038aae90] w-32 text-white px-2 py-2"
                >
                  Logout
                </button>
              ) : (
                <a
                  href="/login"
                  className="flex items-center justify-center rounded-lg bg-gradient-to-br from-[#038aae] to-[#038aae90] w-32 text-white px-2 py-2"
                >
                  Login
                </a>
              )}
            </div>
          </div>
          <div className="-mr-2 flex sm:flex lg:hidden">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-[#038aae] hover:bg-gray-200 focus:outline-none focus:bg-gray-200 focus:text-[#038aae]"
              aria-expanded="false"
            >
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 py-4 space-y-1 sm:px-3">
          {!isLoggedIn && (
            <Link
              to="find-companion"
              className="block text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
              onClick={handleLinkClick}
            >
              View companions
            </Link>
          )}
          {!isLoggedIn && (
            <Link
              to="become-companion"
              className="block text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
              onClick={handleLinkClick}
            >
              Become a companion
            </Link>
          )}
          <Link
            to="/about-us"
            className="block text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
            onClick={handleLinkClick}
          >
            About Us
          </Link>
          <Link
            to="/our-services"
            className="block text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
            onClick={handleLinkClick}
          >
            Our Services
          </Link>
          {/* <Link
            to="https://docs.google.com/forms/d/1Jx8ImcZcoNEVQ3mxifi464Uj3k9oCjfhbv5KuUaft2k/prefill"
            target="_blank"
            className="block text-black hover:text-[#038aae] px-3 py-2 rounded-md text-md font-medium"
            onClick={handleLinkClick}
          >
            Intake Form
          </Link> */}
          <div className="flex flex-col gap-2 px-3 py-3">
            <div className="relative">
              <div
                className="flex items-center justify-center border border-[#038aae] p-2 gap-2 rounded-lg cursor-pointer"
                onClick={toggleDropdown}
              >
                <button className="text-black">My Portal</button>
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <Link
                    to="https://portal.linkrx.ca/"
                    target="_blank"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Companion
                  </Link>
                  <button
                    onClick={handleSeniorPortalClick}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Senior
                  </button>
                  <Link
                    to={`${process.env.REACT_APP_FACILITY_PORTAL}`}
                    target="_blank"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Facility
                  </Link>
                </div>
              )}
            </div>
            <div className="">
              {isLoggedIn ? (
                <button
                  onClick={logout}
                  className="flex items-center justify-center rounded-lg bg-gradient-to-br from-[#038aae] to-[#038aae90] w-full text-white px-2 py-2"
                >
                  Logout
                </button>
              ) : (
                <a
                  href="/login"
                  className="flex items-center justify-center rounded-lg bg-gradient-to-br from-[#038aae] to-[#038aae90] w-full text-white px-2 py-2"
                >
                  Login
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
