import "./index.css";
import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Pages from "./Routes";
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Pages />
        <ToastContainer />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
