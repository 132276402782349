import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getSearchResult,
  getSearchResultByFacility,
  getSearchResultByLanguage,
  getSearchResultByLanguageAndFacility,
} from "../api/Searchapi"; // Ensure both API calls are imported
import Filter from "../components/Filter";
import CompanionCard from "../components/CompanionCard";
import { handleApiError } from "../api/api";

const FindCompanionSearch = () => {
  const location = useLocation();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguageID, setSelectedLanguageID] = useState("");
  const [selectedFacilityID, setSelectedFacilityID] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ct = searchParams.get("ct");
    const rs = searchParams.get("rs");

    const fetchSearchResults = async () => {
      try {
        setLoading(true);

        // Check if both language and facility are selected
        if (selectedLanguageID && selectedFacilityID) {
          const results = await getSearchResultByLanguageAndFacility(
            selectedLanguageID,
            selectedFacilityID
          );
          setSearchResults(results);
        }
        // Filter by facility only if selected
        else if (selectedFacilityID) {
          const results = await getSearchResultByFacility(selectedFacilityID);
          setSearchResults(results);
        }
        // Filter by language only if selected
        else if (selectedLanguageID) {
          const results = await getSearchResultByLanguage(selectedLanguageID);
          setSearchResults(results);
        }
        // Default search results
        else {
          const results = await getSearchResult(ct, rs);
          setSearchResults(results);
        }
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [location.search, selectedLanguageID, selectedFacilityID]);

  return (
    <div className="bg-gray-100 py-6">
      <div className="flex gap-4 mt-[88px] max-w-7xl mx-auto px-4">
        <div className="w-full">
          <div className="">
            <Filter
              setSelectedLanguageID={setSelectedLanguageID}
              setSelectedFacilityID={setSelectedFacilityID}
            />
          </div>
          <div className="flex flex-wrap items-center justify-between sticky top-20 z-50 w-full bg-gray-100 py-4 px-3">
            <h2 className="text-lg font-semibold">
              {loading ? "" : `${searchResults.length} companions found`}
            </h2>
            <div className="text-xl font-bold">
              Hourly rates for our companions is{" "}
              <span className="text-2xl text-[#038aae]">$29</span>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            {loading ? (
              <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center z-50">
                <div className="loader border-t-4 border-b-4 border-[#038aae] rounded-full w-12 h-12 animate-spin"></div>
              </div>
            ) : (
              searchResults.map((searchResult) => (
                <CompanionCard
                  key={searchResult.id}
                  searchResult={searchResult}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindCompanionSearch;
