import React from "react";

interface ContactModalProps {
  email: string;
  phone: string;
  hourlyRate: string; // Add hourlyRate prop
  isOpen: boolean;
  onClose: () => void;
}

const ContactModal: React.FC<ContactModalProps> = ({
  email,
  phone,
  hourlyRate, // Destructure hourlyRate
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
      <div className="w-[400px] sm:w-[500px] bg-white p-4 rounded-lg shadow-xl mx-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold">For booking</h2>
          <span
            onClick={onClose}
            className="cursor-pointer text-3xl bg-[#038aae] text-white rounded-full px-3"
          >
            &times;
          </span>
        </div>
        <div className="mb-6">
          <div className="flex items-start justify-start gap-6 mb-4">
            <h3 className="text-lg font-semibold text-gray-700">Hourly Rate: </h3>
            <p className="text-xl font-bold text-[#038aae] mb-4">
              {hourlyRate}
            </p>
          </div>

          <h3 className="font-semibold">Send us a mail</h3>
          <a
            href="mailto:contact@linkrx.ca"
            className="text-[#038aae] underline"
          >
            {/* {email} */}
            Contact@linkrx.ca
          </a>
        </div>
        <div className="mb-4">
          <h3 className="font-semibold">Or Call</h3>
          <a href="tel:+17783184402" className="text-[#038aae] underline">
            +1 7783184402
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
