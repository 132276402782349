// Routes.tsx

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import FindCompanion from "./pages/FindCompanion";
import BecomeCompanion from "./pages/BecomeCompanion";
import VerifyAccount from "./pages/VerifyAccount";
import MultiStepRegistration from "./components/MultiStepReg";
import FindCompanionSearch from "./pages/FindCompanionSearch";
import TermsAndConditions from "./pages/TermsAndConditions";
import Login from "./pages/Login";
import SeniorPortal from "./pages/SeniorPortal";
import RegistrationCompleted from "./pages/RegistrationCompleted";
import ExploreCompanion from "./pages/ExploreCompanion";
import AboutUs from "./pages/About";
import Services from "./pages/Services";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Loader from "./components/Loader";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import About from "./pages/About";

const Pages: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay or set up actual data fetching
    const timeout = setTimeout(() => {
      setLoading(false); // Hide loader after data is loaded
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  
  return (
      <Layout>
        {loading && <Loader />}
        {!loading && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/find-companion" element={<FindCompanion />} />
          <Route path="/become-companion" element={<BecomeCompanion />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/companion-step-1" element={<CompanionStepOne />} /> */}
          {/* <Route path="/companion-step-1" element={<CompanionStepOne />} /> */}
          <Route path="/companion-registration" element={<MultiStepRegistration />} />
          <Route path="/find-companions" element={<FindCompanionSearch />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/senior-portal" element={<SeniorPortal />} />
          <Route path="/registration-completed" element={<RegistrationCompleted />} />
          <Route path="/explore-companion/:companionId" element={<ExploreCompanion />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-services" element={<Services />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        )}
      </Layout>
  );
};

export default Pages;
