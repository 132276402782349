import React, { useEffect, useState } from "react";
import FileUpload from "./FileUpload";
import CustomDatePicker from "../utils/DatePicker";
import Selection from "./Selection";
import axios from "axios";
import {
  getEducationQualificationItem,
  getExperienceItem,
  getLanguage,
} from "../api/general/stepApi";
import {
  AddCompanionExperience,
  AddEducation,
  AddLanguage,
  handleApiError,
} from "../api/api";
import { getMinDate } from "../utils/MinDate";

interface Step3Props {
  onNext: () => void;
  onPrev: () => void;
}

interface QualificationSelection {
  educationalQualificationItemID: string;
  educationalQualificationItemName: string;
}

interface ExperienceSelection {
  experienceItemID: string;
  experience: string;
}

interface LanguageSelection {
  languageID: string;
  name: string;
}

const Step3: React.FC<Step3Props> = ({ onPrev, onNext }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    new Date()
  );
  const minDate = getMinDate();
  const [ageMessage, setAgeMessage] = useState("");
  const [educationalQualification, setEducationalQualification] = useState<
    QualificationSelection[]
  >([]);
  const [experiences, setExperiences] = useState<ExperienceSelection[]>([]);
  const [languages, setLanguages] = useState<LanguageSelection[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedExperienceItemID, setSelectedExperienceItemID] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageID, setSelectedLanguageID] = useState("");
  const [selectedQualificationName, setSelectedQualificationName] =
    useState("");
  const [selectedQualificationID, setSelectedQualificationID] = useState("");
  const [selectedEducationLevel, setSelectedEducationLevel] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<any>(null);

  const token = sessionStorage.getItem("token");

  const handleError = (error: any) => {
    setError(error);
  };

  const isAtLeast18YearsOld = (date: Date): boolean => {
    const currentDate = new Date();
    const minAllowedDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() - 18)
    );
    return date <= minAllowedDate;
  };

  const isSubmitFormValid = () => {
    // Check if all required fields have been filled
    const requiredFields = [
      selectedStartDate,
      file,
      textAreaValue,
      // videoUrl,
    ];

    const allFieldsFilled = requiredFields.every((field) => field !== "");

    const fileUploaded = file !== null;

    // Age validation check
    const isOldEnough = selectedStartDate
      ? isAtLeast18YearsOld(selectedStartDate)
      : false;

    return allFieldsFilled && fileUploaded && isOldEnough;
  };

  const isNextFormValid = () => {
    // Check if all required fields have been filled
    const requiredFields = [
      selectedQualificationName,
      selectedExperience,
      selectedLanguage,
      selectedEducationLevel,
    ];

    const allFieldsFilled = requiredFields.every((field) => field !== "");

    return allFieldsFilled;
  };

  const educationLevel = [
    { value: "phd", label: "PHD" },
    { value: "MasterDegree", label: "Master Degree" },
    { value: "FirstDegree", label: "First Degree" },
    { value: "diploma", label: "Diploma" },
    { value: "highschoolcert", label: "Hight School Certification" },
  ];

  const handleFileSelect = (file: File) => {
    console.log(file);
    setFile(file);
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const currentDate = new Date();
      const minAllowedDate = new Date(
        currentDate.setFullYear(currentDate.getFullYear() - 18)
      );
      console.log({ minAllowedDate });
      console.log({ date });
      if (date <= minAllowedDate) {
        setAgeMessage("");
        setSelectedStartDate(date);
      } else {
        setAgeMessage("You must be at least 18 years old.");
        setSelectedStartDate(null); // Clear the date if it's invalid
      }
    } else {
      setSelectedStartDate(null); // Clear the date if no date is selected
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append("ProfilePhoto", file);
      }
      if (!selectedStartDate) {
        setError("Date of Birth is required and must be valid.");
        return;
      }
      formData.append("DateOfBirth", selectedStartDate.toISOString());
      formData.append("IntroText", textAreaValue);
      formData.append("VideoUrl", videoUrl);

      const apiUrl = `${process.env.REACT_APP_API_URL}UpdateCompanion`;
      setSubmitLoading(true);
      await axios.patch(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      window.scrollTo(0, 0);
      setSubmitLoading(false);
      // Set submit success to true if the submission is successful
      setSubmitSuccess(true);
    } catch (error) {
      handleApiError(error);
      // Handle error
      setSubmitLoading(false);
      console.error("Error submitting data:", error);
    }
  };

  useEffect(() => {
    const fetchEducationQualifications = async () => {
      try {
        const eduQualifictionData = await getEducationQualificationItem();
        setEducationalQualification(eduQualifictionData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching regions:", error);
      }
    };

    fetchEducationQualifications();
  }, []);

  const formattedEducationalQualification = educationalQualification.map(
    (item) => ({
      value: item.educationalQualificationItemID.toString(),
      label: item.educationalQualificationItemName,
    })
  );

  useEffect(() => {
    const fetchExperienceItem = async () => {
      try {
        const experienceData = await getExperienceItem();
        setExperiences(experienceData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching regions:", error);
      }
    };

    fetchExperienceItem();
  }, []);

  const formattedExperience = experiences.map((item) => ({
    value: item.experienceItemID.toString(),
    label: item.experience,
  }));

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languageData = await getLanguage();
        setLanguages(languageData);
      } catch (error) {
        handleApiError(error);
        console.error("Error fetching regions:", error);
      }
    };

    fetchLanguages();
  }, []);

  const formattedLanguage = languages.map((item) => ({
    value: item.languageID.toString(),
    label: item.name,
  }));

  // <----------------------------- Onchange handler ----------------------------------------->

  const handleExperienceChange = (value: string) => {
    // Find the selected experience object from the options array
    const selectedOption = experiences.find((value) => value.experienceItemID);

    // If selectedOption is found, update the state with the selected experience
    if (selectedOption) {
      setSelectedExperience(selectedOption.experience);
      setSelectedExperienceItemID(selectedOption.experienceItemID.toString()); // Convert to string if needed
    } else {
      setSelectedExperience("");
      setSelectedExperienceItemID("");
    }
  };

  const handleLanguageChange = (value: string) => {
    // Find the selected experience object from the options array
    const selectedOption = languages.find((value) => value.languageID);

    // If selectedOption is found, update the state with the selected experience
    if (selectedOption) {
      setSelectedLanguage(selectedOption.name);
      setSelectedLanguageID(selectedOption.languageID.toString()); // Convert to string if needed
    } else {
      setSelectedLanguage("");
      setSelectedLanguageID("");
    }
  };

  const handleQualificationChange = (value: string) => {
    // Find the selected experience object from the options array
    const selectedOption = educationalQualification.find(
      (value) => value.educationalQualificationItemName
    );

    // If selectedOption is found, update the state with the selected experience
    if (selectedOption) {
      setSelectedQualificationID(selectedOption.educationalQualificationItemID);
      setSelectedQualificationName(
        selectedOption.educationalQualificationItemName.toString()
      ); // Convert to string if needed
    } else {
      setSelectedQualificationID("");
      setSelectedQualificationName("");
    }
  };

  const handleEducationChange = (value: string) => {
    setSelectedEducationLevel(value);
  };

  // <----------------------------- Onchange handler ----------------------------------------->

  const payload = {
    experience: selectedExperience,
    experienceItemID: selectedExperienceItemID,
    companionID: sessionStorage.getItem("companionId"),
  };

  const languagePayload = {
    languageID: selectedLanguageID,
    companionID: sessionStorage.getItem("companionId"),
  };

  const educationPayload = {
    educationalQualificationName: selectedQualificationName,
    institution: "",
    highestEducationalDegree: selectedEducationLevel,
    educationalQualificationItemID: selectedQualificationID,
    companionID: sessionStorage.getItem("companionId"),
  };

  const handleNextClick = async () => {
    const companionID = sessionStorage.getItem("companionId");
    if (!companionID) {
      console.error("Companion ID is not available.");
      return;
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is not available.");
      return;
    }

    const pageCount = 4;

    try {
      setLoading(true);
      await AddCompanionExperience(payload, token)
        .then(await AddLanguage(languagePayload, token))
        .then(await AddEducation(educationPayload, token, pageCount));

      setLoading(false);
      window.scrollTo(0, 0);
      sessionStorage.setItem("page", `${pageCount}`);
      onNext();
    } catch (error) {
      handleApiError(error);
      setLoading(false);
      handleError(error);
    }
  };

  return (
    <div className="">
      {!submitSuccess && (
        <div className="w-[400px] sm:w-[500px]">
          <div className="text-center">
            <h1 className="text-3xl py-4">Getting to know you</h1>
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="py-2 text-lg text-center">Upload Photo</h2>
            <FileUpload onFileSelect={handleFileSelect} />
          </div>
          <div className="py-6 flex flex-col text-start">
            <h2 className="text-xl py-1 text-center">Tell us about Yourself</h2>
            <p className="text-md max-w-[400px] mx-auto sm:max-w-[500px] text-gray-600 text-center">
              Write a little about yourself or upload a video telling us about
              yourself below.
            </p>
            <textarea
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              name=""
              id=""
              className="border-gray-300 rounded-md border my-3 h-[100px]"
            ></textarea>
            <div className="py-2 flex flex-col gap-1">
              <label htmlFor="video" className=" text-start text-gray-500">
                Video Link (optional)
              </label>
              <input
                type="text"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                className="p-1 border border-gray-300 rounded-md"
              />
            </div>
            <CustomDatePicker
              label="Date of Birth"
              selectedDate={selectedStartDate || new Date()}
              onSelectDate={handleStartDateChange}
            />
            {ageMessage && <p className="py-2 text-red-500">{ageMessage}</p>}
          </div>
          <div className="flex items-center justify-between">
            <div
              className={`flex item-center justify-center bg-gradient-to-br from-[#038aae] to-[#038aae90] hover:bg-[#038aae] transition-all
              text-white sm:w-[200px] rounded-lg mb-4`}
            >
              <button className="px-4 py-3" onClick={onPrev}>
                Previous
              </button>
            </div>

            <div
              className={`flex item-center justify-center ${
                submitSuccess ? "" : ""
              } text-white sm:w-[200px] rounded-lg mb-4`}
            >
              <button
                className={`rounded-lg ${
                  !isSubmitFormValid() || loading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-gradient-to-br from-[#038aae] to-[#038aae90]"
                } text-white px-4 py-3 hover:bg-[#038aae] transition-all`}
                onClick={handleSubmit}
                disabled={!isSubmitFormValid()}
              >
                {submitLoading ? "Submitting..." : "Submit Section"}
              </button>
            </div>
          </div>
        </div>
      )}
      {submitSuccess && (
        <div className="w-[400px] sm:w-[500px]">
          <div className="py-2 text-center ">
            <p className="text-gray-500 py-3 max-w-[500px] text-md">
              Select one of the following options, if you have more than one
              entry, kindly select the recent and update your profile with
              remaining
            </p>
            <h2 className="py-2 text-xl">Education and Qualifications</h2>
            <p className="text-gray-500 py-1 text-center max-w-[500px] text-md">
              Select any of the following specialties you have been educated and
              certified on.
            </p>
          </div>
          <div className="">
            <Selection
              label="Qualification"
              options={formattedEducationalQualification}
              onChange={handleQualificationChange}
            />
            <Selection
              label="Education Level"
              options={educationLevel}
              onChange={handleEducationChange}
            />
          </div>

          <div className="py-4 text-center ">
            <h2 className="py-2 text-xl">Experience</h2>
            <p className="text-gray-500 py-1 text-center max-w-[500px] text-md">
              Select any of the following areas in which you are most
              experienced in.
            </p>
          </div>
          <div className="">
            <Selection
              label="Experience"
              options={formattedExperience}
              onChange={handleExperienceChange}
            />
          </div>

          <div className="py-4 text-center ">
            <h2 className="py-2 text-xl">Language</h2>
            <p className="text-gray-500 py-1 text-center max-w-[500px] text-md">
              Select your preferred language (English is defaultly added).
            </p>
          </div>
          <div className="">
            <Selection
              label="Languages"
              options={formattedLanguage}
              onChange={handleLanguageChange}
            />
          </div>

          <button
            onClick={handleNextClick}
            disabled={!isNextFormValid()}
            className={`rounded-lg ${
              !isNextFormValid() || loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-gradient-to-br from-[#038aae] to-[#038aae90] hover:bg-[#038aae] transition-all text-white sm:w-[200px] rounded-lg mb-4"
            } text-white px-3 py-2 mb-4`}
          >
            {loading ? "Loading..." : "Next"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Step3;
