import React from "react";

interface InputProps {
    label: string;
    placeholder: string;
    type: string;
    value: string;
    maxLength?: number; // Allow overriding the default maxLength
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.ChangeEventHandler<HTMLInputElement>;
    pattern?: string;
}

const Input: React.FC<InputProps> = ({ label, placeholder, type, value, maxLength = 50, onChange, onBlur, pattern }) => {
  return (
    <div className="py-2 flex flex-col gap-1 w-full">
      <label htmlFor={label}>{label}</label>
      <div className="relative">
        {type === "tel" && <span className="absolute inset-y-0 bg-gray-100 my-1 ml-1 left-0 flex items-center px-2 text-black">+1</span>}
        <input
          type={type}
          value={value}
          placeholder={`Enter your ${placeholder}`}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          pattern={pattern}
          className={`w-full rounded-md p-2 border-gray-400 border outline-none focus:border-[#038aae] focus:border-2 ${type === "tel" ? "pl-8" : ""}`}
          required
        />
      </div>
    </div>
  );
};

export default Input;
