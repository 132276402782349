export const getEmbedUrl = (url: string) => {
  let embedUrl = "";
  if (url.includes("youtu.be")) {
    const videoId = url.split("youtu.be/")[1];
    embedUrl = `https://www.youtube.com/embed/${videoId}`;
  } else if (url.includes("youtube.com/watch")) {
    const videoId = new URLSearchParams(new URL(url).search).get("v");
    embedUrl = `https://www.youtube.com/embed/${videoId}`;
  }
  return embedUrl;
};
